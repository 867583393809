/* WARNING: Auto-generated by the import-fonts script. Changes here will be overwritten. */

@font-face {
  font-family: "Crimson Pro";
  src: url("./CrimsonProExtraLight-Medium/crimson-pro-medium.woff2") format("woff2"),
    url("./CrimsonProExtraLight-Medium/crimson-pro-medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./Inter-SemiBold/inter-semi-bold.woff2") format("woff2"),
    url("./Inter-SemiBold/inter-semi-bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("./Inter-Regular/inter-regular.woff2") format("woff2"),
    url("./Inter-Regular/inter-regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
